import axios from '@/common/axios'
import qs from 'qs'

export function addQualityInfo (data) {
  return axios({
    method: 'post',
    url: '/universal/feedback/add',
    data: qs.stringify(data)
  })
}

export function deleteQualityInfo (id) {
  return axios({
    method: 'delete',
    url: '/universal/feedback/delete/' + id
  })
}

export function updateQualityInfo (data) {
  return axios({
    method: 'put',
    url: '/universal/feedback/update',
    data: qs.stringify(data)
  })
}

export function selectQualityInfoInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/feedback/info/' + id
  })
}

export function selectQualityInfoList (query) {
  return axios({
    method: 'get',
    url: '/universal/feedback/list',
    params: query
  })
}
