<template>
  <div id="qualityInfo">
    <el-dialog
      :title="qualityInfoFormTitle"
      width="1200px"
      :visible.sync="qualityInfoDialogVisible"
      :close-on-click-modal="false"
      @close="qualityInfoDialogClose"
    >
      <el-form
        ref="qualityInfoFormRef"
        :model="qualityInfoForm"
        :rules="qualityInfoFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="formNo">
              <el-input v-model="qualityInfoForm.formNo" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="qualityInfoForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产批号" prop="batchNo">
              <el-input v-model="qualityInfoForm.batchNo" placeholder="请输入生产批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="规格型号" prop="spec">
              <el-input v-model="qualityInfoForm.spec" placeholder="请输入规格型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="qualityInfoForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="反映部门/人员" prop="deptPerson">
              <el-input v-model="qualityInfoForm.deptPerson" placeholder="请输入反映部门/人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="反映时间" prop="reflectionTime">
              <el-date-picker v-model="qualityInfoForm.reflectionTime" placeholder="请选择反映时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="质量问题描述" prop="qualityProblemDescription">
              <el-input
                v-model="qualityInfoForm.qualityProblemDescription"
                placeholder="请输入质量问题描述"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="描述人" prop="describePerson">
              <el-input v-model="qualityInfoForm.describePerson" placeholder="请输入描述人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="describeDate">
              <el-date-picker v-model="qualityInfoForm.describeDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="确定责任部门/人员" prop="responsibleDeptPerson">
              <el-input
                v-model="qualityInfoForm.responsibleDeptPerson"
                placeholder="请输入确定责任部门/人员"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="管理者代表" prop="manager">
              <el-input v-model="qualityInfoForm.manager" placeholder="请输入管理者代表" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="managerDate">
              <el-date-picker v-model="qualityInfoForm.managerDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="质量问题分析和结果" prop="problemAnalysisResults">
              <el-input
                v-model="qualityInfoForm.problemAnalysisResults"
                placeholder="请输入质量问题分析和结果"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="责任部门或人员" prop="responsibleDeptPerson1">
              <el-input v-model="qualityInfoForm.responsibleDeptPerson1" placeholder="请输入责任部门或人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="responsibleDeptPerson1Date">
              <el-date-picker v-model="qualityInfoForm.responsibleDeptPerson1Date" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="解决方案/计划" prop="solutionPlan">
              <el-input
                v-model="qualityInfoForm.solutionPlan"
                placeholder="请输入解决方案/计划"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="责任部门或人员" prop="responsibleDeptPerson2">
              <el-input v-model="qualityInfoForm.responsibleDeptPerson2" placeholder="请输入责任部门或人员" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="responsibleDeptPerson2Date">
              <el-date-picker v-model="qualityInfoForm.responsibleDeptPerson2Date" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="处理意见" prop="handlingOpinions">
              <el-radio-group v-model="qualityInfoForm.handlingOpinions">
                <el-radio :label="1">
                  需要采取纠正和预防措施
                </el-radio>
                <el-radio :label="2">
                  不需要采取纠正和预防措施
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="纠正或预防措施实施表编号" prop="implementationNo" label-width="200px">
              <el-input v-model="qualityInfoForm.implementationNo" placeholder="请输入纠正或预防措施实施表编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="理由" prop="reason">
              <el-input v-model="qualityInfoForm.reason" placeholder="请输入理由" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="责任人" prop="responsible">
              <el-input v-model="qualityInfoForm.responsible" placeholder="请输入责任人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="responsibleDate">
              <el-date-picker v-model="qualityInfoForm.responsibleDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="qualityInfoDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="qualityInfoFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="qualityInfoPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="生产批号" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="deptPerson" label="反映部门/人员" />
      <el-table-column label="反映时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reflectionTime">{{ scope.row.reflectionTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="qualityInfoPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addQualityInfo, deleteQualityInfo, updateQualityInfo, selectQualityInfoInfo, selectQualityInfoList } from '@/api/universal/qualityInfo'

export default {
  data () {
    return {
      qualityInfoDialogVisible: false,
      qualityInfoFormTitle: '',
      qualityInfoForm: {
        id: '',
        formNo: '',
        productName: '',
        batchNo: '',
        spec: '',
        quantity: '',
        deptPerson: '',
        reflectionTime: '',
        qualityProblemDescription: '',
        describePerson: '',
        describeDate: '',
        responsibleDeptPerson: '',
        manager: '',
        managerDate: '',
        problemAnalysisResults: '',
        responsibleDeptPerson1: '',
        responsibleDeptPerson1Date: '',
        solutionPlan: '',
        responsibleDeptPerson2: '',
        responsibleDeptPerson2Date: '',
        handlingOpinions: '',
        responsible: '',
        responsibleDate: '',
        implementationNo: '',
        reason: ''
      },
      qualityInfoFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      qualityInfoPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectQualityInfoList(this.searchForm).then(res => {
      this.qualityInfoPage = res
    })
  },
  methods: {
    qualityInfoDialogClose () {
      this.$refs.qualityInfoFormRef.resetFields()
    },
    qualityInfoFormSubmit () {
      if (this.qualityInfoFormTitle === '质量信息反馈表详情') {
        this.qualityInfoDialogVisible = false
        return
      }
      this.$refs.qualityInfoFormRef.validate(async valid => {
        if (valid) {
          if (this.qualityInfoFormTitle === '新增质量信息反馈表') {
            await addQualityInfo(this.qualityInfoForm)
          } else if (this.qualityInfoFormTitle === '修改质量信息反馈表') {
            await updateQualityInfo(this.qualityInfoForm)
          }
          this.qualityInfoPage = await selectQualityInfoList(this.searchForm)
          this.qualityInfoDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.qualityInfoFormTitle = '新增质量信息反馈表'
      this.qualityInfoDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteQualityInfo(row.id)
        if (this.qualityInfoPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.qualityInfoPage = await selectQualityInfoList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.qualityInfoFormTitle = '修改质量信息反馈表'
      this.qualityInfoDialogVisible = true
      this.selectQualityInfoInfoById(row.id)
    },
    handleInfo (index, row) {
      this.qualityInfoFormTitle = '质量信息反馈表详情'
      this.qualityInfoDialogVisible = true
      this.selectQualityInfoInfoById(row.id)
    },
    selectQualityInfoInfoById (id) {
      selectQualityInfoInfo(id).then(res => {
        this.qualityInfoForm.id = res.id
        this.qualityInfoForm.formNo = res.formNo
        this.qualityInfoForm.productName = res.productName
        this.qualityInfoForm.batchNo = res.batchNo
        this.qualityInfoForm.spec = res.spec
        this.qualityInfoForm.quantity = res.quantity
        this.qualityInfoForm.deptPerson = res.deptPerson
        this.qualityInfoForm.reflectionTime = res.reflectionTime
        this.qualityInfoForm.qualityProblemDescription = res.qualityProblemDescription
        this.qualityInfoForm.describePerson = res.describePerson
        this.qualityInfoForm.describeDate = res.describeDate
        this.qualityInfoForm.responsibleDeptPerson = res.responsibleDeptPerson
        this.qualityInfoForm.manager = res.manager
        this.qualityInfoForm.managerDate = res.managerDate
        this.qualityInfoForm.problemAnalysisResults = res.problemAnalysisResults
        this.qualityInfoForm.responsibleDeptPerson1 = res.responsibleDeptPerson1
        this.qualityInfoForm.responsibleDeptPerson1Date = res.responsibleDeptPerson1Date
        this.qualityInfoForm.solutionPlan = res.solutionPlan
        this.qualityInfoForm.responsibleDeptPerson2 = res.responsibleDeptPerson2
        this.qualityInfoForm.responsibleDeptPerson2Date = res.responsibleDeptPerson2Date
        this.qualityInfoForm.handlingOpinions = res.handlingOpinions
        this.qualityInfoForm.responsible = res.responsible
        this.qualityInfoForm.responsibleDate = res.responsibleDate
        this.qualityInfoForm.implementationNo = res.implementationNo
        this.qualityInfoForm.reason = res.reason
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectQualityInfoList(this.searchForm).then(res => {
        this.qualityInfoPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectQualityInfoList(this.searchForm).then(res => {
        this.qualityInfoPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectQualityInfoList(this.searchForm).then(res => {
        this.qualityInfoPage = res
      })
    }
  }
}
</script>

<style>
</style>
